<template>
  <div>
    <a href="http://relaxingsleepsounds.com"
      ><font-awesome-icon class="go-back" :icon="goBack"
    /></a>
    <h1 class="title">Relaxing Sleep Sounds</h1>
    <div class="columns">
      <AudioPlayer
        class="audio-player-padding"
        v-for="item in items"
        :item="item"
        v-bind:key="item"
      />
    </div>
  </div>
</template>

<script>
import AudioPlayer from "./components/AudioPlayer.vue";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "App",
  components: {
    AudioPlayer,
    FontAwesomeIcon,
  },
  data: function () {
    return {
      goBack: faChevronLeft,
      items: [
        {
          audioTrack: {
            name: "Thunderstorms",
            audioUrl:
              "https://feeds.soundcloud.com/stream/745129018-relaxing-sleep-sounds-heavythunder5half.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Healing Music",
            audioUrl:
              "https://feeds.soundcloud.com/stream/956280550-relaxing-sleep-sounds-reclaim-relaxing-soundscapes-relaxing-sleep-sounds.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Babbling Brook",
            audioUrl:
              "https://feeds.soundcloud.com/stream/800492440-relaxing-sleep-sounds-forest-brook.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Ocean Waves",
            audioUrl:
              "https://feeds.soundcloud.com/stream/749070469-relaxing-sleep-sounds-ocean5half.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Amazon Rain",
            audioUrl:
              "https://feeds.soundcloud.com/stream/744177628-relaxing-sleep-sounds-rain5half.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "White Noise",
            audioUrl:
              "https://feeds.soundcloud.com/stream/741659656-relaxing-sleep-sounds-whitenoise5.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Brown Noise",
            audioUrl:
              "https://feeds.soundcloud.com/stream/756463987-relaxing-sleep-sounds-brownnoise5half.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Pink Noise",
            audioUrl:
              "https://feeds.soundcloud.com/stream/745023763-relaxing-sleep-sounds-pink5.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Blue Noise",
            audioUrl:
              "https://feeds.soundcloud.com/stream/746581540-relaxing-sleep-sounds-blue5.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Box Fan",
            audioUrl:
              "https://feeds.soundcloud.com/stream/753088675-relaxing-sleep-sounds-fan5half.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Crackling Fireplace",
            audioUrl:
              "https://feeds.soundcloud.com/stream/801764863-relaxing-sleep-sounds-fireplace5half.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Birds Chirping",
            audioUrl:
              "https://feeds.soundcloud.com/stream/741883255-relaxing-sleep-sounds-birdsounds2.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Jurassic Night",
            audioUrl:
              "https://feeds.soundcloud.com/stream/887871775-relaxing-sleep-sounds-jurassic-island.mp3",
            imageUrl: "picture.jpeg",
          },
        },
        {
          audioTrack: {
            name: "Coffee Shop",
            audioUrl:
              "https://feeds.soundcloud.com/stream/871580785-relaxing-sleep-sounds-coffeeshop6.mp3",
            imageUrl: "picture.jpeg",
          },
        },
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 60px;
}

html {
  background-color: black;
  background-image: url("~@/assets/treenight.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.title {
  font-size: 32px;
  color: ghostwhite;
  top: 0;
  text-align: center;
  position: absolute;
  left: 15%;
  right: 15%;
}

.go-back {
  font-size: 32px;
  color: ghostwhite;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  margin: 10px;
}

.audio-player-padding {
  margin-bottom: 10px;
}

.columns {
  margin-top: 5px;
  column-count: 2;
}

@media only screen and (max-width: 850px) {
  .title {
    font-size: 28px;
  }
  .go-back {
    font-size: 28px;
  }
}

@media only screen and (max-width: 750px) {
  .title {
    font-size: 24px;
    padding: 10px;
  }
  .go-back {
    font-size: 24px;
  }
}

@media only screen and (max-width: 650px) {
  .title {
    font-size: 20px;
    padding: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .columns {
    column-count: 1;
    margin-top: 0px;
  }
  .audio-player-padding {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 850px) {
  .columns {
    margin-top: 15px;
    column-count: 3;
  }
}
</style>
