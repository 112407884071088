

<template>
  <div v-if="item" class="white-text">
    <span @click="togglePlay(item)"
      ><span class="margin-right">{{ item.audioTrack.name }}</span>
      <span v-if="!isPlaying"><font-awesome-icon :icon="playIcon" /></span>
      <span v-if="isPlaying"><font-awesome-icon :icon="pauseIcon" /></span
    ></span>
    <br />

    <div v-if="isPlaying" class="volume-control">
      <font-awesome-icon
        @click="mute()"
        class="volume-icon"
        :icon="volumeDownIcon"
      />
      <input
        class="volume-slider"
        type="range"
        id="volume-slider"
        max="100"
        v-model="volume"
      />
      <font-awesome-icon
        @click="maxVolume()"
        class="volume-icon"
        :icon="volumeUpIcon"
      />
    </div>

    <audio preload="none" hidden controls loop :id="item.audioTrack.audioUrl">
      <source :src="item.audioTrack.audioUrl" type="audio/mpeg" />
    </audio>
  </div>
</template>

<script>
import {
  faPause,
  faPlay,
  faVolumeDown,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "AudioPlayer",
  components: {
    FontAwesomeIcon,
  },
  watch: {
    volume(volume) {
      var audio = document.getElementById(this.item.audioTrack.audioUrl);
      audio.volume = volume / 100;
    },
  },
  methods: {
    mute: function () {
      this.volume = 0;
    },
    maxVolume: function () {
      var audio = document.getElementById(this.item.audioTrack.audioUrl);

      this.volume = 100;

      if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true;
      }
    },
    togglePlay: function (item) {
      var audio = document.getElementById(item.audioTrack.audioUrl);
      if (this.isPlaying) {
        audio.pause();
        this.isPlaying = false;
      } else {
        audio.play();
        this.isPlaying = true;
      }
    },
  },
  data: function () {
    return {
      playIcon: faPlay,
      pauseIcon: faPause,
      volumeDownIcon: faVolumeDown,
      volumeUpIcon: faVolumeUp,
      isPlaying: false,
      volume: 100,
    };
  },
  props: ["item"],
};
</script>
 

<style scoped>
.white-text {
  color: ghostwhite;
  font-size: 24px;
  padding: 10px;
}
.margin-right {
  margin-right: 15px;
}

.volume-control {
  margin: 0;
}

.volume-icon {
  color: ghostwhite;
  font-size: 12px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 30px;
  width: 12px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 30px;
  width: 12px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 30%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 30%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 30%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}

input[type="range"]::-moz-range-track {
  width: 30%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type="range"]::-ms-track {
  width: 30%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type="range"]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #367ebd;
}

@media only screen and (max-width: 575px) {
  .white-text {
    color: ghostwhite;
    font-size: 24px;
    padding: 5px;
  }
}
</style>
